<template>
  <div class="row" v-if="Object.keys(transaction).length !== 0">
    <transaction-header-detail :transaction="transaction" :spinning="spinning" :delayTime="delayTime"></transaction-header-detail>
    <transaction-header-status :current="current" :transaction="transaction"></transaction-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th>SL</th>
              <th>ACC Code</th>
              <th>COA Group</th>
              <th>ACC Name</th>
              <th>Expense At(Month)</th>
              <th>Requested Amount(BDT)</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requested_transaction_item, index) in transaction.requested_transaction_items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ requested_transaction_item.coa_master.acc_code }}</td>
              <td>
                {{ requested_transaction_item.coa_master.coa_group.name }}
                {{ requested_transaction_item.coa_master.coa_sub_group1 ? '->' : '' }}
                {{ requested_transaction_item.coa_master.coa_sub_group1 ? requested_transaction_item.coa_master.coa_sub_group1.name : '' }}
                {{ requested_transaction_item.coa_master.coa_sub_group2 ? '->' : '' }}
                {{ requested_transaction_item.coa_master.coa_sub_group2 ? requested_transaction_item.coa_master.coa_sub_group2.name : '' }}
              </td>
              <td>{{ requested_transaction_item.coa_master.name }}</td>
              <td>{{ requested_transaction_item.expense_at }}</td>
              <td class="text-right">{{ currencyConverter(requested_transaction_item.requested_amount) }}</td>
              <td>{{ requested_transaction_item.comment }}</td>
            </tr>
            <tr>
              <th style="text-align: right" colspan="5">TOTAL</th>
              <td class="text-right">{{ currencyConverter(transaction.total_requested_amount)}}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import transactionHeaderDetail from '@/views/expense/partial/transaction_header_detail'
import transactionHeaderStatus from '@/views/expense/partial/transaction_header_status'

export default {
  name: 'admin_view',
  components: { transactionHeaderDetail, transactionHeaderStatus },
  data() {
    return {
      transaction: {},
      loading: false,
      show: false,
      spinning: false,
      delayTime: 500,
      current: 0,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getRequestTransaction()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRequestTransaction() {
      const requestTransactionId = this.$route.params.requested_transaction_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/transaction/view/' + requestTransactionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.transaction = data.transaction
          this.current = data.max_request_transaction_item_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    currencyConverter(x) {
      return this.$localCurrency(x)
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
